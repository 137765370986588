<script>
import {Carousel, Slide} from "vue-carousel";
import {
  UserIcon,
  MailIcon,
  KeyIcon,
  ArrowUpIcon,
  HelpCircleIcon,
} from "vue-feather-icons";

import Switcher from "@/components/switcher";
import Footer from "@/components/footer";
import planApi from "@/apis/planApi";
import storage from '../storage';
import Features from "@/components/features";
import Plans from "@/components/plans";
import Testimonial from "@/components/testimonial";
import date from "../date";
import Plan2 from "../components/planDetail.vue";
import Navbar2 from "../components/navbar2.vue";
import back from "../components/back.vue";

/**
 * Index-1
 */
export default {
  data() {
    return {
      plans: undefined,
      plansLoad: 0,
      login: false,
      planForm: {
        pageNum: 1,
        pageSize: 20
      },
      bound: undefined,
      boundMsg: '',
      show: false,
      showData: {},
      countdown: {}
    }
  },
  components: {
    Switcher,
    Carousel,
    Slide,
    UserIcon,
    MailIcon,
    KeyIcon,
    ArrowUpIcon,
    Footer,
    HelpCircleIcon,
    Plans,
    Features,
    Testimonial,
    Plan2,
    Navbar2,
    back
  },
  mounted() {
    this.timer = setInterval(() => this.updateCountdown(), 1000);
  },
  created() {
    planApi.mine(this.planForm, res => {
      this.plans = res.data.page
      this.bound = res.data.bound
      if (this.bound) {
        this.boundMsg = 'Invalid Status: '+this.bound.msg
      }
      if (this.plans.result.length < this.planForm.pageSize) this.plansLoad = -1

      this.updateCountdown();
    })


    let userId = storage.localGet("userId")
    if (userId) {
      this.login = true
    }
  },
  destroyed() {
    clearInterval(this.timer);
  },
  methods: {
    updateCountdown(){
      const currentTime = new Date();
      if (!this.plans || !this.plans.result || this.plans.result.length === 0) {
        return;
      }
      this.plans.result.forEach(it=>{
        const endTime = new Date(it.endTime);
        const timeDiff = endTime - currentTime;
        if (timeDiff > 0) {
          const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
          const hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
          const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);
          this.$set(this.countdown, it.id, `${days}d ${hours}h ${minutes}m ${seconds}s`);
        } else {
          this.$set(this.countdown, it.id, '0');
        }
      })


    },
    loadmore(page) {
      //开始加载
      this.plansLoad = 1
      this.planForm.pageNum = page
      //获取项目列表
      planApi.mine(this.planForm, res => {
        this.plansLoad = 0
        Object.assign(this.plans, res.data.page)
      })
    },
    loadmore2() {
      this.planForm.pageNum++
      this.plansLoad = 1
      let pages = Math.ceil(this.plans.totalCount / this.planForm.pageSize)
      if (pages >= this.planForm.pageNum) {
        planApi.mine(this.planForm, res => {
          res.data.page.result.unshift(...this.plans.result)
          Object.assign(this.plans, res.data.page)
          this.plansLoad = 0
          if (pages <= this.planForm.pageNum) this.plansLoad = -1
        })
      } else {
        this.plansLoad = -1;
      }
    },
    ShowModal(data) {
      this.showData = data.plan
      this.show = true
    },
    getDays(ob) {
      const mistiming = this.formatDateTimeSecond(ob)
      if (mistiming > 0) {
        var days = Math.floor(mistiming / 1000 / 60 / 60 / 24)
      }
      return `${days}`
    },
    formatDateTimeSecond(ob) {
      const start_date = ob.startTime
      const end_date = ob.endTime
      if (start_date === '' || !start_date) {
        return ''
      }
      if (end_date === '' || !end_date) {
        return ''
      }
      var date1 = new Date(start_date)
      var date2 = new Date(end_date)
      return date2.getTime() - date1.getTime()
    },
  },
  filters: {
    getDate(data) {
      return date.getDate(data)
    },
    getTime(data) {
      return date.getTime(data)
    }
  }
};
</script>

<template>
  <div>
    <back></back>
    <!--Shape End-->
    <!-- 项目列表 -->
    <section style="margin-top: 20px;margin-bottom: 80px;">
      <div class="container">
      </div>
      <!--end container-->
      <div class="container">
        <el-row :gutter="20">
          <el-col :span="14" :xs="24">
            <h4>My Orders</h4>
          </el-col>
        </el-row>
        <el-row class="mb-2" v-if="this.bound !== null && this.boundMsg !== ''">
          <el-alert type="error" show-icon :closable="false" :title="this.boundMsg">
          </el-alert>
        </el-row>
        <el-row v-if="plans==undefined" v-loading="plans==undefined" element-loading-background="rgba(0, 0, 0, 0)">
        </el-row>
        <div v-else class="row">
          <div class="col-24" style="width: 100%;padding: 5px;">
            <div class="table-responsive bg-white shadow rounded mt-4">
              <table class="table mb-0 table-center" v-loading="plansLoad==1">
                <thead class="bg-light">
                <tr>
                  <th scope="col" class="plan-column">Plan</th>
                  <th scope="col" class="text-center">Quantity</th>
                  <th scope="col" class="text-center">Amount</th>
                  <th scope="col" class="text-center">Profit</th>
                  <th scope="col" class="text-center">Start Time</th>
                  <th scope="col" class="text-center">End Time</th>
                  <th scope="col" class="text-center">Remain Time</th>
                  <th scope="col" class="text-center">Status</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="it in plans.result" :key="it.id">
                  <td class="text-center">
                    <a @click="ShowModal(it)" style="cursor: pointer;color: #0a0a1a;"
                       class="forum-title  font-weight-bold">
                      {{ it.plan.name }}
<!--                      <span v-if="it.trial">(Trial)</span>-->
                    </a>
                    <div>
                      ${{ it.amount/it.quantity }} / {{ getDays(it) }} {{ getDays(it) === '1' ? 'Day' : 'Days' }}
                    </div>
                  </td>
                  <td class="text-center small">{{ it.quantity }}</td>
                  <td class="text-center small">${{ it.amount }}</td>
                  <td class="text-center small">${{ it.netProfit }}</td>
                  <td class="text-center small" style="white-space:pre-wrap;">{{ it.startTime | getTime }}</td>
                  <td class="text-center small" style="white-space:pre-wrap;">{{ it.endTime | getTime }}</td>
                  <td class="text-center small" style="white-space:pre-wrap;color:red;">{{ countdown[it.id] }}</td>
                  <td class="text-center small">
                    <b-badge v-if="it.status === 0" variant="danger">Expire</b-badge>
                    <b-badge v-if="it.status === -1" variant="warning">Invalid</b-badge>
                    <b-badge v-if="it.status === 1" variant="success">Valid</b-badge>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <Plan2 :item="showData" :show="show" @modal="show = !show"></Plan2>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
        <div class="mt-3 window">
          <div style="display: flex;justify-content: center;">
            <b-pagination v-if="plans!=undefined" :disabled="plansLoad == 1" size="lg" v-model="planForm.pageNum"
                          @change="loadmore" :total-rows="plans.totalCount" :per-page="planForm.pageSize"
                          first-text="First"
                          prev-text="Prev"
                          next-text="Next"
                          last-text="Last">
            </b-pagination>
          </div>
        </div>
        <div class="mt-3 phone">
          <div v-if="plans!=undefined" class="d-grid gap-2">
            <b-button v-if="plansLoad == 0" block class="btn btn-primary" @click="loadmore2">Load More</b-button>
            <b-button v-else-if="plansLoad == 1" block class="btn btn-primary disabled">Loading...</b-button>
            <b-button v-else block class="btn btn-primary disabled">No More</b-button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<style scoped lang="scss">

.rounded{
  border-radius: 12px !important;
}
.table thead th{
  border-bottom: none;
  border-top: none;
}

.table{
  color: rgba(59, 80, 108, 1);
}

.table tbody td{
  color: #333333
}

.bg-light{
  background-color: #f5f7fa !important;
}


.plan-column{
  //width: 280px;
  text-align: center!important;
}


</style>
